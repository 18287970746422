












import Vue from 'vue'
import { Component } from 'vue-property-decorator'

let Moment = require('moment')

@Component
export default class LayoutPage extends Vue {
  banners: any = {}

  hasExpired(banner) {
    return (Moment.now() > Moment(banner.endDate, 'YYYY/MM/DD'))
  }

  hasStarted(banner) {
    return (Moment.now() > Moment(banner.startDate, 'YYYY/MM/DD'))
  }

  created() {
    this.banners = this.$root.config.banners || {}
  }
}

