

























































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import axios from 'axios'
import env from '@/env'
import { mapGetters, mapState } from 'vuex'

@Component({
  computed: {
    ...mapGetters(['raisedPercentage']),
  },
})
export default class VHeader extends Vue {
  donations!: any
  raisedPercentage!: number

  showSearch = true

  @Watch('$route.path', { immediate: true })
  onPathChanged(val: string) {
    this.showSearch = !(val === '/')
  }

  q: string | null = null
  dialog: boolean = false

  submit() {
    let q = this.q
    if (!q || this.$route.params.uid === q) return
    this.dialog = false

    if (q.match(/^\d+$/)) { // if searching for `block`
      this.$router.push({ name: 'Block', params: { height: q } })
    } else if (q.match(/^[QA]\w{33}$/)) { // address length 34, starts with Q or A
      this.$router.push({ name: 'Address', params: { uid: q } })
    } else {
      this.$axios.get(`/names/${q}`)
          .then((r: any) => {
            if (r.data && r.data.owner) {
              this.$router.push({ name: 'Address', params: { uid: r.data.owner } })
            }
            if (r?.data?.error && q) {
              this.$router.push({ name: 'Address', params: { uid: q } })
            }
          })
          .catch((r: any) => {
            if (q) {
              this.$router.push({ name: 'Address', params: { uid: q } })
            }
          })
    }


  }
}

