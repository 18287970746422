















import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

import VHeader from '@/components/VHeader.vue'
import VSidebar from '@/components/VSidebar.vue'
import DialogNodeState from '@/components/DialogNodeState.vue'
import SnackbarVersionUpdate from '@/components/SnackbarVersionUpdate.vue'

@Component({
  components: { SnackbarVersionUpdate, DialogNodeState, VSidebar, VHeader },
})
export default class App extends Vue {
  $refs!: { leftSidebar: any }

  toggleSidebar() {
    this.$refs.leftSidebar.toggle()
  }

  @Watch('$route.name')
  onPathChanged(val: string) {
    if (this.$route.name !== 'Donate') { // Donate page always re-loads donations data
      this.$store.dispatch('getDonationData')
    }
  }
}
