import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'
import IconStackedLineChart from '@/components/IconStackedLineChart.vue'
import MdiChartAreaspline from "@/components/icons/MdiChartAreaspline.vue";
import MdiCalculator from "@/components/icons/MdiCalculator.vue";

Vue.use(Vuetify)

// (`icons` type error so we use `any`)
export default new Vuetify(<any>{
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      light: {},
      dark: {
        'background-1': '#121212'
      },
    },
  },
  icons: {
    values: {
      stackedLineChart: { // name of our custom icon
        component: IconStackedLineChart, // our custom component
      },
      mdiChartAreaspline : {
        component: MdiChartAreaspline
      },
      mdiCalculator : {
        component: MdiCalculator
      },
    },
  },
})
