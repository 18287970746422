

























































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

@Component({
  $_veeValidate: { validator: 'new' },
})
export default class VSidebar extends Vue {
  drawer: boolean = this.$vuetify.breakpoint.lgAndUp
  mini: boolean = false
  confirmed: boolean = false

  customNodeDialog: boolean = false
  useCustomNode: boolean = false
  customNodeURL: string = '' // default localhost `http://localhost:12391`
  form1 = {
    customNodeURL: ''
  }

  @Watch('useCustomNode')
  onUseCustomNodeChanged(val) {
    localStorage.setItem('useCustomNode', val ? 'true' : 'false')
  }

  @Watch('customNodeDialog')
  onCustomNodeDialogChanged(val) {
    if (val) {
      const defaultCustomNodeURL = 'http://localhost:12391'
      this.form1.customNodeURL = this.customNodeURL || defaultCustomNodeURL
    }
  }

  saveCustomNode() {
    this.customNodeDialog = false
    if (this.customNodeURL !== this.form1.customNodeURL) {
      this.customNodeURL = this.form1.customNodeURL
      localStorage.setItem('customNodeURL', this.customNodeURL)
      this.useCustomNode = !!this.customNodeURL
      if (this.useCustomNode) setTimeout(() => location.reload(), 300) // reload page
    }
  }

  toggleUseCustomNode() {
    if (!this.customNodeURL) {
      this.customNodeDialog = true
    } else {
      this.useCustomNode = !this.useCustomNode
      setTimeout(() => location.reload(), 300) // reload page
    }
  }

  created() {
    this.useCustomNode = (localStorage.getItem('useCustomNode') === 'true')
    this.confirmed = this.useCustomNode
    this.customNodeURL = localStorage.getItem('customNodeURL') || ''
  }

  items: any[] = [
    { icon: 'home', text: 'Home', to: { name: 'Home' } },
    { icon: '$mdiChartAreaspline', text: 'Market', to: { name: 'Market' } },
    { icon: '$mdiCalculator', text: 'Calculator', to: { name: 'Calculator' } },
    // { icon: 'bar_chart', text: 'Network stats', to: { name: 'Network' } },
    // { icon: 'chat', text: 'Qchat', to: { name: 'QChat' } },
    { icon: 'emoji_events', text: 'Richlist', to: { name: 'Richlist' } },
    { icon: 'language', text: 'Known peers', to: { name: 'Peers' } },
    { icon: 'favorite', text: 'Donate', to: { name: 'Donate' } },
  ]

  onClick() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.drawer = false
    }
  }

  toggle() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.mini = !this.mini
    } else {
      this.drawer = !this.drawer
    }
  }
}

