




import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mdiCalculator } from '@mdi/js'
@Component
export default class VSidebar extends Vue {
  svgPath = mdiCalculator
}
