































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import TablePeers from '@/components/pages/TablePeers.vue'

@Component({
  components: { TablePeers },
  $_veeValidate: { validator: 'new' },
})
export default class DialogNodeState extends Vue {
  inputNodeURL: string = ''
  nodeData: any = { value: null, isLoading: false, error: null }

  loadingState: number = 0 // we use multiple API calls so loadingState encapsulates all of them.

  show: boolean = false
  data: any = null

  @Watch('show')
  onDialogToggle(val) {
    if (val) {
      // reset initial data
      this.inputNodeURL = ''
      this.nodeData = { value: null, isLoading: false, error: null }
    }
  }

  @Watch('computedNodeURL')
  fetchNodeData() {
    this.getNodeData(this.computedNodeURL)
  }

  @Watch('loadingState')
  onLoadingStateChanged(val) {
    this.nodeData.isLoading = (val !== 3) // states `1` and `2` mean we are waiting for at least one more API call
  }

  created() {
    this.$eventBus.$on('open-node-state-dialog', (data) => {
      this.show = true
      this.data = data
    })
  }

  blockHeight() {
    return this.nodeData.value?.blockHeight
  }

  buildVersion() {
    return this.nodeData.value?.info?.buildVersion
  }

  peersCount() {
    return this.nodeData.value?.peers?.length
  }

  get computedNodeURL(): string {
    const matches = this.inputNodeURL.match(/^(?:(https?):\/\/)?((?:\d{1,3}\.){3}\d{1,3})(?:(?::)(\d+))?(?:\/)?$/)

    if (!matches) return ''
    const defaultAPIPort = 12391
    const defaultProtocol = 'http'

    const protocol = matches[1] || defaultProtocol // protocol
    const IP = matches[2] // IP
    const APIPort = matches[3] || defaultAPIPort // port

    const computedURL = `${protocol}://${IP}:${APIPort}`

    return computedURL
  }

  getNodeData(nodeURL) {
    // reset data
    this.loadingState = 0
    this.nodeData = { value: null, isLoading: false, error: null }

    if(!nodeURL) return this.loadingState = 3

    let nodeData: any = {}

    const requestTimeout = 2000 // in miliseconds
    //@TODO use single API call when it gets available (then also remove `loadingState`)
    this.$axios(`${nodeURL}/blocks/height`, { timeout: requestTimeout })
        .then((r: any) => nodeData.blockHeight = r.data)
        .finally(r => this.loadingState++)

    this.$axios(`${nodeURL}/peers`, { timeout: requestTimeout })
        .then((r: any) => nodeData.peers = r.data)
        .finally(r => this.loadingState++)

    this.$axios(`${nodeURL}/admin/info`, { timeout: requestTimeout })
        .then((r: any) => nodeData.info = r.data)
        .finally(r => this.loadingState++)

    this.$set(this.nodeData, 'value', nodeData)
  }

}

