import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'

Vue.config.productionTip = false
import 'material-design-icons-iconfont/dist/material-design-icons.css'

require('@/sass/main.scss')
require('@/sass/scrollbar.scss')

import env from '@/env'
import axios from 'axios'


import LayoutPage from '@/components/layouts/LayoutPage.vue'
import VLink from '@/components/VLink.vue'
import VLoader from '@/components/VLoader.vue'
import VTimeago from '@/components/VTimeago.vue'

Vue.component('layout-page', LayoutPage)
Vue.component('v-link', VLink)
Vue.component('v-loader', VLoader)
Vue.component('v-timeago', VTimeago)

Vue.prototype.$eventBus = new Vue()

import store from './store'

import VeeValidate from 'vee-validate'
// we disable automatic injection https://baianat.github.io/vee-validate/concepts/injections.html#injecting-parent-validator
Vue.use(VeeValidate, { inject: false })

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

const lsTest = () => {
  let test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}
Vue.prototype.$lsIsAvailable = lsTest()

/**
 * @param num
 * @returns Language sensitive representation of the `num`
 */
Vue.prototype.$formatNumber = (num: string | number): string => {
  num = +num
  return num.toLocaleString()
}

/**
 * @param num
 * @param maximumFractionDigits
 * @returns Language sensitive representation of the `num`
 */
Vue.prototype.$formatCurrency = (num: string | number, maximumFractionDigits: number = 8): string => {
  num = +num
  return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits})
}

/**
 * English pluralize
 *
 * @param count
 * @param word
 * @param suffix
 * @param trimLast - removes last character of singular `word`
 */
Vue.prototype.$pluralize = (count: number, word: string, suffix: string = 's', trimLast: boolean = false): string => {
  let str = count + ' '
  str += (count === 1) ?
    word
    : trimLast
      ? word.slice(0, -1) + suffix
      : word + suffix
  return str
}

let axiosConfig = {
  baseURL: '',
}
fetch('/config/config.json').then(res => res.json()).then(config => {
  const nodes = config.nodes

  if (lsTest() && localStorage.getItem('useCustomNode') === 'true') {
    if(localStorage.getItem('customNodeURL')) {
      axiosConfig.baseURL = localStorage.getItem('customNodeURL') || '' // @TODO error
    }
  } else {
    axiosConfig.baseURL = nodes[Math.floor(Math.random() * nodes.length)] // use random node from the list
  }
  Vue.prototype.$axios = axios.create(axiosConfig)

  new Vue({
    data: {
      config: config,
    },
    router,
    vuetify,
    store,
    render: h => h(App),
  }).$mount('#app')
}).catch(() => console.error('Error fetching the config file.'))