export const calculateTotalMinted = (blockHeight: number): number => {
  // block reward params
  const interval = 259200 // block reward reduces every 259200 blocks; @TODO (reduces only up to 11 times)
  const subtract = 0.25 // block reward reduced by 0.25
  let reward = 5 // initial block reward is 5
  // - - -

  let total: number = 0
  let blocksLeft = blockHeight

  while (blocksLeft) {
    total += ((blocksLeft > interval) ? interval : blocksLeft) * reward

    reward -= subtract
    blocksLeft -= (blocksLeft > interval) ? interval : blocksLeft
  }
  return total
}