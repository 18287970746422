











// see also https://gist.github.com/185driver/96f53ccd8e29119bc64708dab41cc6b3
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class SnackbarVersionUpdate extends Vue {
  refreshing: boolean = false
  registration: any = null

  snackBtnText: string = ''
  snackWithBtnText: string = ''
  snackbar: boolean = false
  timeout: number = -1

  showRefreshUI(e) {
    // Display a snackbar inviting the user to refresh/reload the app due to an app update being available.
    // The new service worker is installed, but not yet active.
    // Store the ServiceWorkerRegistration instance for later use.
    this.registration = e.detail
    this.snackBtnText = 'Refresh'
    this.snackWithBtnText = 'New version available!'
    this.snackbar = true
  }

  refreshApp() {
    // localStorage.setItem('bookmarklet_alert_hidden_at', '')
    this.snackbar = false
    // Protect against missing registration.waiting.
    if (!this.registration || !this.registration.waiting) {
      return
    }
    this.registration.waiting.postMessage('skipWaiting')
  }

  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker && navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!localStorage.getItem('hasPreviousSW')) {
        localStorage.setItem('hasPreviousSW', 'true')
        return
      }
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  }


}

