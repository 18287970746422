


































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class TablePeers extends Vue {
  @Prop() peers!: any[]
  @Prop({ required: false }) highestBlock!: number

  headers: any[] = [
    { text: 'Address', value: 'address', sortable: false, width: 220 },
    { text: 'Last height', value: 'lastHeight', sortable: false, width: 100, align: 'right' },
    { text: 'Version', value: 'version', sortable: false, width: 200 },
    { text: '', value: '', sortable: false },
  ]
}

