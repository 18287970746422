import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { calculateTotalMinted } from '@/utilities/calculateTotalMinted'

Vue.use(Vuex)

const foreignBlockchain: string = 'LITECOIN'

export default new Vuex.Store({
  state: {
    tradeBot: {
      btcFee: 0.0002_5000, // @TODO move to config
      txCount: 3, // @TODO move to config
    },
    rates: {
      BTC: { USD: null, LTC: null, QORT: null },
      LTC: { USD: null },
      DOGE: { USD: null },
      DGB: { USD: null },
      RVN: { USD: null },
      ARRR: { USD: null },
      QORT: { USD: null, BTC: null, LTC: null, DOGE: null, DGB: null, RVN: null, ARRR: null },
    },
    decimals: {
      USD: 2,
      QORT: 2,
      BTC: 8,
      LTC: 4,
      DOGE: 2,
      DGB: 2,
      RVN: 2,
      ARRR: 2,
    },
    chain: {
      isLoading: false,
      blockHeight: 0,
      totalMinted: 0,
    },
    donations: {
      address: 'QabKkey3Y325zv9Y4XHtTksfC6HictMNkJ', // @TODO: move to config (or get from config)
      raisedThisMonth: 0,
      monthlyGoalAmount: 1500, // QORT
      isLoading: true,
      error: false,
    },
  },
  getters: {
    raisedPercentage(state) {
      return Math.trunc(state.donations.raisedThisMonth / state.donations.monthlyGoalAmount * 100)
    },
  },
  mutations: {
    updateRates(state, { key1, key2, value }) {
      Vue.set(state.rates[key1], key2, value)
    },
    SET_CHAIN_DATA(state, { key, value }) {
      state.chain[key] = value
    },
  },
  actions: {
    getDonationData({ state }) {
      state.donations.error = false
      state.donations.isLoading = true

      const axios = (<any>this)._vm.$axios

      const now = new Date()
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1) // get first day of the current month
      const timestamp = firstDay.getTime() // start of current month timestamp

      axios.get(`/blocks/timestamp/${timestamp}`)
        .then(r => {
          const startBlock = r.data.height
          /* @TODO: commented out startBlock because it slows down API, drastically, for some reason,
           *        thus also might need to remove or update some other relevant code such as that blocks api call above
           */
          const params = {
            // startBlock: startBlock,
            txType: 'PAYMENT',
            address: state.donations.address,
            confirmationStatus: 'CONFIRMED',
            limit: 0,
          }
          // search for payments made this month
          axios.get('/transactions/search', { params })
            .then(r => {
              let txs = r.data
              txs = txs.filter(tx => tx.timestamp >= timestamp)
              let amounts = txs.filter((tx: any) => tx.recipient === state.donations.address).map((tx: any) => +tx.amount)
              state.donations.raisedThisMonth = amounts.reduce((a: number, b: number) => (a + b), 0).toFixed(8) // total received
            })
            .catch(() => { // could not load payments
              state.donations.error = true
            })
            .finally(() => state.donations.isLoading = false)

        })
        .catch(() => { // could not load block
          state.donations.error = true
          state.donations.isLoading = false
        })
    },

    getNodeData({ commit }) {
      commit('SET_CHAIN_DATA', { key: 'isLoading', value: true });

      (<any>this)._vm.$axios('/blocks/height').then(r => {
        const blockHeight = r.data
        const totalMinted = calculateTotalMinted(blockHeight)
        commit('SET_CHAIN_DATA', { key: 'blockHeight', value: blockHeight })
        commit('SET_CHAIN_DATA', { key: 'totalMinted', value: totalMinted })
      }).finally(() => commit('SET_CHAIN_DATA', { key: 'isLoading', value: false }))

    },
    getAllRates({ state, commit }) {
      let coingeckoParams = {
        ids: ['bitcoin', 'litecoin', 'dogecoin', 'digibyte', 'ravencoin', 'pirate-chain'].join(','),
        vs_currencies: ['usd', 'ltc', 'doge', 'dgb', 'rvn', 'arrr'].join(','),
      }
      const tradesCount = 20
      const now = (new Date).getTime()
      const minimumTimestamp =  now - 3 * 24 * 60 * 60 * 1000 // 3 days

      Promise.all([
        (<any>this)._vm.$axios(`/crosschain/tradeoffers`, { params: { foreignBlockchain: 'LITECOIN', reverse: true, limit: 100 } }),
        (<any>this)._vm.$axios(`/crosschain/trades`, { params: { foreignBlockchain: 'LITECOIN', reverse: true, limit: tradesCount, minimumTimestamp } }),
        (<any>this)._vm.$axios(`https://api.coingecko.com/api/v3/simple/price`, { params: coingeckoParams }),
      ]).then((r) => {
        // handle QORTAL API responses
        let orders = r[0].data
        orders = orders.filter(o => { // filter for `presence` orders
          return o.creatorPresenceExpiry > Date.now()
        })
        let QORT_LTC_orders = 0
        if (orders.length) {
          orders = orders.map(o => {
            o.price = (+o.expectedForeignAmount) / (+o.qortAmount)
            return o
          })
          orders = orders.sort((a, b) => a.price - b.price) // sort by price ASC
          QORT_LTC_orders = orders[0].price
        }

        let trades = r[1].data
        /*
          // @TODO following 2 lines are temporary filter for getting first 5 trades because core returns double resources than asked for (10 instead of 5 for example)
          trades.sort((a, b) => b.tradeTimestamp - a.tradeTimestamp) // order by most recent trades (so we can filter out trailing ones)
          trades = trades.slice(0, tradesCount) // filter out buggy extra trailing trades
        */

        // algo for representing more accurate price
        trades.sort((a, b) => a.qortAmount - b.qortAmount) // order by lowest QORT amount
        const limitFiltered = 10
        const minQortAmount = 10
        let count = 0

        trades = trades.filter(t => { // filter out low amount trades
          if ((t.qortAmount <= minQortAmount) && (count < limitFiltered)) { // also prevent removing too many orders
            count++
            return false
          }
          return true
        })

        trades.sort((a, b) => b.tradeTimestamp - a.tradeTimestamp) // order by most recent trades (so we can filter out trailing ones)
        trades = trades.slice(0, Math.floor(tradesCount / 2)) // keep only most recent 10 trades
        const countTradesTemp = trades.length // count again to remove more trades
        trades = trades.slice(0, Math.floor(countTradesTemp / 2))
        // Finally, we base the price on the most recent trades with sufficient qortAmount traded

        // - - -
        let btcAmountAvg = trades.map(t => +t.btcAmount).reduce((a, v, i) => (a * i + v) / (i + 1))
        let qortAmountAvg = trades.map(t => +t.qortAmount).reduce((a, v, i) => (a * i + v) / (i + 1))

        let QORT_LTC_trades = btcAmountAvg / qortAmountAvg
        let QORT_LTC = (QORT_LTC_orders > QORT_LTC_trades) ? QORT_LTC_orders : QORT_LTC_trades
        let LTC_QORT = 1 / QORT_LTC
        commit('updateRates', { key1: 'QORT', key2: 'LTC', value: QORT_LTC })
        commit('updateRates', { key1: 'BTC', key2: 'QORT', value: LTC_QORT })
        if (state.rates.QORT.LTC && state.rates.LTC.USD) { // @ts-ignore
          const QORT_USD = state.rates.QORT.LTC * state.rates.LTC.USD
          commit('updateRates', { key1: 'QORT', key2: 'USD', value: QORT_USD })
        }
        // handle COINGECKO response

        commit('updateRates', { key1: 'BTC', key2: 'USD', value: r[2].data.bitcoin.usd })
        commit('updateRates', { key1: 'BTC', key2: 'LTC', value: r[2].data.bitcoin.ltc })
        commit('updateRates', { key1: 'LTC', key2: 'USD', value: r[2].data.litecoin.usd })
        commit('updateRates', { key1: 'DOGE', key2: 'USD', value: r[2].data.dogecoin.usd })
        commit('updateRates', { key1: 'DGB', key2: 'USD', value: r[2].data.digibyte.usd })
        commit('updateRates', { key1: 'RVN', key2: 'USD', value: r[2].data.ravencoin.usd })
        commit('updateRates', { key1: 'ARRR', key2: 'USD', value: r[2].data['pirate-chain'].usd })
        if (state.rates.QORT.LTC && state.rates.LTC.USD) { // @ts-ignore
          const QORT_USD = state.rates.QORT.LTC * state.rates.LTC.USD
          commit('updateRates', { key1: 'QORT', key2: 'USD', value: QORT_USD })

          for (const [coin, value] of Object.entries(state.rates.QORT)) {
            if (!['LTC', 'USD'].includes(coin)) { // add rates for other pairs based on QORT/USD price and their respective USD price
              const coinPriceUSD = state.rates[coin].USD
              commit('updateRates', { key1: 'QORT', key2: coin, value: QORT_USD / coinPriceUSD })
            }
          }
        }
      })
    },
    // @TODO: remove (deprecated)
    getCoinGeckoPrices({ state, commit }) {
      let params = {
        ids: ['bitcoin', 'litecoin', 'dogecoin'].join(','),
        vs_currencies: ['usd', 'ltc', 'doge'].join(','),
      }
      axios(`https://api.coingecko.com/api/v3/simple/price`, { params })
        .then(r => {
          commit('updateRates', { key1: 'BTC', key2: 'USD', value: r.data.bitcoin.usd })
          commit('updateRates', { key1: 'BTC', key2: 'LTC', value: r.data.bitcoin.ltc })
          commit('updateRates', { key1: 'LTC', key2: 'USD', value: r.data.litecoin.usd })
          commit('updateRates', { key1: 'DOGE', key2: 'USD', value: r.data.dogecoin.usd })
          if (state.rates.QORT.LTC && state.rates.LTC.USD) { // @ts-ignore
            const QORT_USD = state.rates.QORT.LTC * state.rates.LTC.USD
            commit('updateRates', { key1: 'QORT', key2: 'USD', value: QORT_USD })
          }
        })
    },
    // @TODO: remove (deprecated)
    async getLatestQortRates({ state, commit }) {

      Promise.all([
        (<any>this)._vm.$axios(`/crosschain/tradeoffers`, { params: { foreignBlockchain: 'LITECOIN', reverse: true, limit: 100 } }),
        (<any>this)._vm.$axios(`/crosschain/trades`, { params: { foreignBlockchain: 'LITECOIN', reverse: true, limit: 5 } }),
      ]).then((r) => {
        let orders = r[0].data
        orders = orders.filter(o => { // filter for `presence` orders
          return o.creatorPresenceExpiry > Date.now()
        })
        let QORT_LTC_orders = 0
        if (orders.length) {
          orders = orders.map(o => {
            o.price = (+o.expectedForeignAmount) / (+o.qortAmount)
            return o
          })
          orders = orders.sort((a, b) => a.price - b.price) // sort by price ASC
          QORT_LTC_orders = orders[0].price
        }

        let trades = r[1].data
        // @TODO following 2 lines are temporary filter for getting first 5 trades because core returns double resources than asked for (10 instead of 5 for example)
        trades.sort((a, b) => b.tradeTimestamp - a.tradeTimestamp) // order by most recent trades (so we can filter out trailing ones)
        trades = trades.slice(0, 5) // filter out buggy extra trailing trades

        // - - -
        let btcAmountAvg = trades.map(t => +t.btcAmount).reduce((a, v, i) => (a * i + v) / (i + 1))
        let qortAmountAvg = trades.map(t => +t.qortAmount).reduce((a, v, i) => (a * i + v) / (i + 1))

        let QORT_LTC_trades = btcAmountAvg / qortAmountAvg
        let QORT_LTC = (QORT_LTC_orders > QORT_LTC_trades) ? QORT_LTC_orders : QORT_LTC_trades
        let LTC_QORT = 1 / QORT_LTC
        commit('updateRates', { key1: 'QORT', key2: 'LTC', value: QORT_LTC })
        commit('updateRates', { key1: 'BTC', key2: 'QORT', value: LTC_QORT })
        if (state.rates.QORT.LTC && state.rates.LTC.USD) { // @ts-ignore
          const QORT_USD = state.rates.QORT.LTC * state.rates.LTC.USD
          commit('updateRates', { key1: 'QORT', key2: 'USD', value: QORT_USD })
        }
      })

      // (<any>this)._vm.$axios(`/crosschain/trades`, { params: { foreignBlockchain: 'LITECOIN', reverse: true, limit: 5 } })
      //   .then(r => {
      //     let trades = r.data
      //     // @TODO following 2 lines are temporary filter for getting first 5 trades because core returns double resources than asked for (10 instead of 5 for example)
      //     trades.sort((a, b) => b.tradeTimestamp - a.tradeTimestamp) // order by most recent trades (so we can filter out trailing ones)
      //     trades = trades.slice(0, 5) // filter out buggy extra trailing trades
      //
      //     // - - -
      //     let btcAmountAvg = trades.map(t => +t.btcAmount).reduce((a, v, i) => (a * i + v) / (i + 1))
      //     let qortAmountAvg = trades.map(t => +t.qortAmount).reduce((a, v, i) => (a * i + v) / (i + 1))
      //
      //     let QORT_LTC = btcAmountAvg / qortAmountAvg
      //     let LTC_QORT = qortAmountAvg / btcAmountAvg
      //     commit('updateRates', { key1: 'QORT', key2: 'LTC', value: QORT_LTC })
      //     commit('updateRates', { key1: 'BTC', key2: 'QORT', value: LTC_QORT })
      //     if (state.rates.QORT.LTC && state.rates.LTC.USD) { // @ts-ignore
      //       const QORT_USD = state.rates.QORT.LTC * state.rates.LTC.USD
      //       commit('updateRates', { key1: 'QORT', key2: 'USD', value: QORT_USD })
      //     }
      //   })

      /*
      // we use provided API for computing the price
      (<any>this)._vm.$axios(`/crosschain/price/${foreignBlockchain}`)
        .then(r => {
          const QORT_LTC = 1 / (r.data / Math.pow(10, 8))
          const LTC_QORT = 1 / QORT_LTC
          commit('updateRates', { key1: 'QORT', key2: 'LTC', value: QORT_LTC })
          commit('updateRates', { key1: 'LTC', key2: 'QORT', value: LTC_QORT })
        })
       */

    },
  },
})
