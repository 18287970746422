import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/',  name: 'Home', component: () => import(/* webpackChunkName: "home" */ '../components/pages/HomePage.vue') },
  { path: '/richlist',  name: 'Richlist', component: () => import(/* webpackChunkName: "richlist" */ '../components/pages/RichlistPage.vue') },
  { path: '/address/:uid',  name: 'Address', props: true, component: () => import(/* webpackChunkName: "address" */ '../components/pages/AddressPage/AddressPage.vue') },
  { path: '/trade/:uid',  name: 'Trade', props: true, component: () => import(/* webpackChunkName: "trade" */ '../components/pages/TradePage/TradePage.vue') },
  { path: '/qchat',  name: 'QChat', component: () => import(/* webpackChunkName: "qchat" */ '../components/pages/QChatPage/QChatPage.vue') },
  { path: '/calculator',  name: 'Calculator', component: () => import(/* webpackChunkName: "calculator" */ '../components/pages/CalculatorPage/CalculatorPage.vue') },
  { path: '/tx/:uid',  name: 'Transaction', props: true, component: () => import(/* webpackChunkName: "tx" */ '../components/pages/TransactionPage.vue') },
  { path: '/peers',  name: 'Peers', props: true, component: () => import(/* webpackChunkName: "peers" */ '../components/pages/PeersPage.vue') },
  { path: '/market',  name: 'Market', props: true, component: () => import(/* webpackChunkName: "market" */ '../components/pages/MarketPage/MarketPage.vue') },
  { path: '/blocks',  name: 'Blocks', props: true, component: () => import(/* webpackChunkName: "blocks" */ '../components/pages/BlocksPage.vue') },
  { path: '/block/:height',  name: 'Block', props: true, component: () => import(/* webpackChunkName: "block" */ '../components/pages/BlockPage.vue') },
  { path: '/donate',  name: 'Donate', props: true, component: () => import(/* webpackChunkName: "donate" */ '../components/pages/DonatePage.vue') },
  { path: '/bl-ss-reward-shares',  name: 'RewardShares', component: () => import(/* webpackChunkName: "rewardShares" */ '../components/pages/RewardSharesPage.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
