



import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

//@ts-ignore
import timeago from 'timeago.js/lib'

@Component
export default class VTimeago extends Vue {
  @Prop({ required: true }) value!: string | number
  @Prop({ default: false, type: Boolean }) ago!: boolean
  @Prop({ default: false, type: Boolean }) full!: boolean
  @Prop({ default: 120 * 1000, type: Number }) intervalTime!: number // in milliseconds

  timeago: string = ''
  timeInterval: NodeJS.Timer | null = null

  get title() {
    return (typeof (this.value) === 'number') ? new Date(this.value) : this.value
  }

  getTimeago() {
    const ONE_MONTH = 30 * 24 * 60 * 60 * 1000
    const ONE_YEAR = ONE_MONTH * 12
    let date = new Date(this.value)
    let now = new Date
    if ((+now - +date) > ONE_MONTH) {
      let monthName = date.toLocaleString('default', { month: 'long' })
      let day = date.getDate()
      // format for older than 1year
      if ((+now - +date) > ONE_YEAR) {
        let year = date.getFullYear()
        return `${monthName.substring(0, 3)} ${year}`
      }
      return `${monthName.substring(0, 3)} ${day}`
    }

    let str = timeago().format(this.value)
    if (!this.full) {
      str = str.replace(/ seconds?/, 's')
          .replace(/ minutes?/, 'm')
          .replace(/ hours?/, 'h')
          .replace(/ days?/, 'd')
          .replace(/ weeks?/, 'w')
    }

    if (!this.ago) {
      str = str.replace(/ ago/, '')
    }

    return str
  }

  created() {
    this.timeago = this.getTimeago()
    this.timeInterval = setInterval(() => {
      this.timeago = this.getTimeago()
    }, this.intervalTime)
  }

  destroyed() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval)
    }
  }
}

