import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,_vm._g(_vm._b({attrs:{"max-width":"700","scrollable":"","content-class":"scoped-dialog"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c(VCard,{attrs:{"min-height":"300"}},[_c(VCardTitle,[_c('div',[_vm._v("Check node state")]),_c('div',{staticClass:"ml-auto"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c(VIcon,[_vm._v("close")])],1)],1)]),_c(VDivider,{staticClass:"mb-3"}),_c(VCardText,{staticClass:"pa-3"},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:({url: {require_protocol: true, require_tld: false}, required: true}),expression:"{url: {require_protocol: true, require_tld: false}, required: true}"}],attrs:{"data-vv-name":"url","error-messages":_vm.errors.collect('url'),"placeholder":"e.g. http://localhost:12391","outlined":"","label":"Node URL"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"grey"}},on),[_vm._v("help")])]}}])},[_c('div',[_vm._v("Make sure that API is accessible.")])])]},proxy:true}]),model:{value:(_vm.inputNodeURL),callback:function ($$v) {_vm.inputNodeURL=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inputNodeURL"}}),(_vm.inputNodeURL && !_vm.nodeData.isLoading)?[(_vm.blockHeight() && _vm.buildVersion() && _vm.peersCount())?[_c('div',{staticClass:"mt-3"},[_vm._v("Node at "+_vm._s(_vm.computedNodeURL))]),_c('div',{staticClass:"d-flex mt-3"},[_c('div',[_c('div',[_vm._v("Block height: "+_vm._s(_vm.blockHeight()))]),_c('div',[_vm._v("Core version: "+_vm._s(_vm.buildVersion()))])]),_c('div',{staticClass:"ml-auto my-auto"},[_c(VBtn,{staticClass:"no-hover-opacity",attrs:{"outlined":""},on:{"click":function($event){return _vm.getNodeData(_vm.computedNodeURL)}}},[_c(VIcon,[_vm._v("refresh")]),_c('span',[_vm._v("Refresh")])],1)],1)]),_c('div',{staticClass:"mt-3"},[_vm._v("Peers "),(_vm.peersCount())?_c('span',[_vm._v("("+_vm._s(_vm.peersCount())+")")]):_vm._e()]),_c('table-peers',{attrs:{"peers":_vm.nodeData.value.peers}})]:_c('div',{staticClass:"pa-3"},[_c('span',[_vm._v("Could not get node data"),(_vm.computedNodeURL)?_c('span',[_vm._v(" from "+_vm._s(_vm.computedNodeURL))]):_vm._e(),_vm._v(". "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.getNodeData(_vm.computedNodeURL)}}},[_vm._v("Try again")])])])]:_vm._e(),(_vm.nodeData.isLoading)?_c('v-loader'):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }